import React, { useEffect, useState } from 'react';
import './BillList.scss';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box, Dialog, Card, CardContent } from '@material-ui/core';
import { http, FxInput, FxSelect, FxCurrency, AlertService } from '../../../fx-core';
import { useHistory } from "react-router-dom";
import { session } from "../../../fx-core/helpers/sessionService";
import { general } from "../../../fx-core/helpers/generalService";
import ConfirmDialog from '../../../general/ConfirmDialog/ConfirmDialog';

const BillList = () => {
    const history = useHistory();

    let defaultFilter: any = {
        billNo: '',
        partyId: -1
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [bills, setBills] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [parties, setParties] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [message, setMessage] = useState(null);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getBills({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getBills(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getBills = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            billNo: filter.billNo,
            partyId: filter.partyId
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filters.billNo = options.filter.billNo;
            filters.partyId = options.filter.partyId;
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `bill/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getBillsCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getBillsCallBack = (res, pagerOptions) => {
        const result = res.data;
        setBills(result.items);
        computePagerData(result, pagerOptions);
    };

    const showBillForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/billform',
            state: stateObj
        });
    };

    const addPayIn = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/addPayIn',
            state: stateObj
        });
    }

    const initLookup = () => {
        let inputData = {
            lookups: {
                Parties: { default: true }
            },
            filters: {
                companyId: session.getItem("companyId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setParties(response.data.Parties);
        });
    }

    const downloadInvoice = (item) => {
        let apiOptions = {
            url: `bill/downloadinvoice/${item.id}`,
            method: 'get',
            fileName: 'BILL_' + item.billNo
        }
        http.download(apiOptions);
    };

    const confirmCancel = (item: any) => {
        setSelectedItem(item);
        setMessage("Do you want to cancel " + item.billNo + "?");
        setOpen(true);
    };

    const confirmMessage = () => {
        setOpen(false);
        cancelBill(selectedItem);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedItem({});
    };

    const cancelBill = (row) => {
        let apiOptions: any = {
            url: `bill/cancel/${row.id}`,
            data: {}
        };
        http.put(apiOptions).then(res => {
            cancelBillCallBack(res);
        });
    };

    const cancelBillCallBack = (res) => {
        AlertService.showSuccessMsg();
        setSelectedItem({});
        let options = { filter: filter };
        getBills(options);
    };

    useEffect(() => {
        initLookup();
        let options = { pager: { pageNo: 1 }, filter: filter };
        getBills(options);
    }, []);

    return (
        <div className="content-container billlist-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Bills</span>
                <span className="text-right add-ui" onClick={showBillForm}><i className="fas fa-plus-circle"></i></span>
            </div>
            <div className="row col-12 pt-3 px-0 justify-content-between">
                <div className="col-5 search">
                    <FxInput name="billNo" variant="outlined" label="Bill No"
                        size="small"
                        value={filter.billNo}
                        onValueChange={handleInputChange}
                    />
                </div>
                <div className="1"></div>
                <div className="col-6 pr-0">
                    <FxSelect name="partyId" variant="outlined" label="Party Name"
                        options={parties}
                        selectedValue={filter.partyId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
            </div>
            {/* <div className="row col-12 px-0">
                <div className="col-8"></div>
                <div className="col-4 pr-0">
                    <span className="float-right pt-2">
                        <Button variant="contained" size="small" color="primary" onClick={showBillForm}>Add</Button>
                    </span>
                </div>
            </div> */}
            <div className="px-2 col-sm-8 pl-3 mobileview">
                {
                    bills.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row">
                                        <span className="col-8 cardrow-ui">#{item.billNo} {item.partyName}</span>
                                        <span className="col-4 text-right cardrow-ui">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.total} />
                                        </span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-2">{general.getFormattedDate(item.billDate)}</span>
                                        <span className="col-6 text-right"><span><i className="fas fa-truck"></i></span> {item.transportMode} / {item.veichleNo}</span>
                                        <span className="col-4 text-right">{item.billStatus}</span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-5">
                                            <span>Paid : </span>
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.paidAmount} />
                                        </span>
                                        <span className="col-5">
                                            <span>Due : </span>
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.dueAmount} />
                                        </span>
                                    </div>

                                    <div className="btn-style row justify-content-end pr-2 pt-2">
                                        {
                                            item.billStatusId === 2 && item.billPaymentStatusId !== 3 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => addPayIn(item)}>
                                                    Pay In
                                                    </div>
                                            </span>
                                        }
                                        <span className="pl-3 pt-1">
                                            <div className="pb-1 px-2 btnstyle-ui" onClick={() => showBillForm(item)}>
                                                {item.billStatusId === 1 ? 'Edit' : 'View'}
                                            </div>
                                        </span>
                                        {
                                            item.billStatusId === 2 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => downloadInvoice(item)}>
                                                    Download
                                                    </div>
                                            </span>
                                        }
                                        {
                                            item.billStatusId === 2 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => confirmCancel(item)}>
                                                    Cancel
                                                    </div>
                                            </span>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }

            </div>

            <div className="px-2 col-sm-12 mx-auto pl-3 tabview">
                {
                    bills.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row">
                                        <span className="col-8 cardrow-ui">#{item.billNo} {item.partyName}</span>
                                        <span className="col-4 text-right cardrow-ui">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.total} />
                                        </span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-2">{general.getFormattedDate(item.billDate)}</span>
                                        <span className="col-6 text-right"><span><i className="fas fa-truck"></i></span> {item.transportMode} / {item.veichleNo}</span>
                                        <span className="col-4 text-right">{item.billStatus}</span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-5">
                                            <span>Paid : </span>
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.paidAmount} />
                                        </span>
                                        <span className="col-5">
                                            <span>Due : </span>
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.dueAmount} />
                                        </span>
                                    </div>

                                    <div className="btn-style row justify-content-end pr-2 pt-2">
                                        {
                                            item.billStatusId === 2 && item.billPaymentStatusId !== 3 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => addPayIn(item)}>
                                                    Pay In
                                                    </div>
                                            </span>
                                        }
                                        <span className="pl-3 pt-1">
                                            <div className="pb-1 px-2 btnstyle-ui" onClick={() => showBillForm(item)}>
                                                {item.billStatusId === 1 ? 'Edit' : 'View'}
                                            </div>
                                        </span>

                                        {
                                            item.billStatusId === 2 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => downloadInvoice(item)}>
                                                    Download
                                                    </div>
                                            </span>
                                        }
                                        {
                                            item.billStatusId === 2 &&
                                            <span className="pl-3 pt-1">
                                                <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => confirmCancel(item)}>
                                                    Cancel
                                                    </div>
                                            </span>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }

            </div>

            <div className="pt-2 px-3 deskview">
                {/* <Container maxWidth="xl" className="pt-2"> */}
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font">Bill No</TableCell>
                                <TableCell className="table-head table-font">Party Name</TableCell>
                                <TableCell className="table-head table-font">Bill Date</TableCell>
                                <TableCell className="table-head table-font">Total</TableCell>
                                <TableCell className="table-head table-font">Paid Amount</TableCell>
                                <TableCell className="table-head table-font">Due Amount</TableCell>
                                <TableCell className="table-head table-font">Veichle No</TableCell>
                                <TableCell className="table-head table-font">Bill Status</TableCell>
                                <TableCell className="table-head table-font">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                bills.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                {row.billNo}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                {row.partyName}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                {general.getFormattedDate(row.billDate)}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={row.total} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={row.paidAmount} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={row.dueAmount} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                {row.veichleNo}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font pt-1">
                                                {row.billStatus}
                                            </div>
                                        </TableCell>
                                        {/* <TableCell>
                                                <div className="table-font pt-1">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={row.subTotal} />
                                                </div>
                                            </TableCell> */}
                                        <TableCell>
                                            <div className="btn-style row">
                                                {
                                                    row.billStatusId === 2 && row.billPaymentStatusId !== 3 &&
                                                    <span className="table-font pl-3">
                                                        <div className="pt-1">
                                                            <Button variant="contained" size="small" color="primary"
                                                                onClick={() => addPayIn(row)}> Pay In
                                                            </Button>
                                                        </div>
                                                    </span>
                                                }
                                                <span className="table-font pl-3">
                                                    <div className="pt-1">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => showBillForm(row)}> {row.billStatusId === 1 ? 'Edit' : 'View'}
                                                        </Button>
                                                    </div>
                                                </span>
                                                {
                                                    row.billStatusId === 2 &&
                                                    <span className="table-font pl-3">
                                                        <div className="pt-1">
                                                            <Button variant="contained" size="small" color="primary"
                                                                onClick={() => downloadInvoice(row)}> Download
                                                            </Button>
                                                        </div>
                                                    </span>
                                                }
                                                {
                                                    row.billStatusId === 2 &&
                                                    <span className="table-font pl-3">
                                                        <div className="pt-1">
                                                            <Button variant="contained" size="small" color="primary"
                                                                onClick={() => confirmCancel(row)}> Cancel
                                                            </Button>
                                                        </div>
                                                    </span>
                                                }
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </Container> */}
            </div>

            <div className="pt-2 pr-0 row col-12 justify-content-between mobileview">
                <div className="col-5 bottom-info pl-4">{pager.pagerInfo}</div>
                <div className="col-7 pr-0 mr-n3">
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={0}
                        paddingRight={0}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <div className="pt-2 pr-0 row col-12 justify-content-between tabview">
                <div className="bottom-info pt-1 pl-3">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={0}
                    paddingRight={0}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            <div className="pt-2 pr-0 row col-12 justify-content-between deskview">
                <div className="bottom-info pt-1 pl-3">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={0}
                    paddingRight={0}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
            </Dialog>
        </div>
    )
};

export default BillList;