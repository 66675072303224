import React, { useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './ChangePassword.css';
import { useHistory } from "react-router-dom";
import { FxInput, http, AlertService, session } from '../fx-core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    margin: `${theme.spacing(0)} auto`
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    background: '#007d3b'
  },
  header: {
    textAlign: 'center',
    background: '#007d3b',
    color: '#ff'
  },
  card: {
    marginTop: theme.spacing(15),
  }
}));


const ChangePassword = () => {

  const classes = useStyles();
  const history = useHistory();
  const [valError, setValError] = useState(null);

  let defaultFormModel: any = {
    userName: '',
    passowrd: ''
  };
  const [formModel, setFormModel] = useState(defaultFormModel);

  const handleInputChange = (item) => {
    handleFormModelChange({ [item.name]: item.value });
  }

  const handleFormModelChange = (data) => {
    setFormModel(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleSubmit = () => {
    setValError(null);
    if (formModel.newPassword !== formModel.confirmPassword) {
      setValError("Password mismatch");
      return;
    }
    let inputData = { newPassword: formModel.newPassword, confirmPassword: formModel.confirmPassword };
    let apiOptions = {
      url: 'users/my/password',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      if (result) {
        AlertService.showSuccessMsg("Password Changed Successfully");
        back();
      }
    });
  }

  const back = () => {
    history.goBack();
  };

  const body = (
    <form className={classes.container} noValidate>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <div>
            <FxInput name="newPassword" variant="standard" label="New Password"
              fullWidth
              type="password"
              value={formModel.newPassword}
              onValueChange={handleInputChange} />

            <FxInput name="confirmPassword" variant="standard" label="Confirm Password"
              fullWidth
              type="password"
              value={formModel.confirmPassword}
              onValueChange={handleInputChange} />
          </div>
        </CardContent>
        {valError &&
          <div className="error"> {valError} </div>}

        <CardActions>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.submitBtn}
            onClick={handleSubmit}
          >
            Submit
        </Button>
        </CardActions>
      </Card>
    </form>
  );

  return (
    <div>
      {body}

    </div>
  );

}
export default ChangePassword;
