import validator from 'validator';
import moment from "moment";
import 'moment/min/locales';


export const FormValidator = {

    bulkValidate(items) {
        let result= {};
        let hasError = false;
        for(let item of items) {
            const validationResult = FormValidator.validate(item); // { name: 'name', value: '', validations: "["required"]"}
            result[item.name] = validationResult;
            if (!hasError) {
                hasError = Object.keys(validationResult).some(val => validationResult[val]);
            }
        }
        return { isValid: !hasError, errors: result } ;
    },

    validate(options) {
        const value = options.value || '';
        const name = options.name;

        if (!name) throw new Error('FormValidator.Validate - Name must not be empty.');

        const param = options.param;
        const validations = options.validations;

        let result = [];
        if (validations && validations.length) {
            /*  Result of each validation must be true if the input is invalid
                and false if valid. */
            validations.forEach(m => {
                switch (m) {
                    case 'required':
                        result[m] = validator.isEmpty(value)
                        break;
                    case 'email':
                        result[m] = !validator.isEmail(value)
                        break;
                    case 'number':
                        result[m] = !validator.isNumeric(value)
                        break;
                    case 'integer':
                        result[m] = !validator.isInt(value)
                        break;
                    case 'alphanum':
                        result[m] = !validator.isAlphanumeric(value)
                        break;
                    case 'url':
                        result[m] = !validator.isURL(value)
                        break;
                    case 'minlen':
                        result[m] = !validator.isLength(value, { min: param })
                        break;
                    case 'maxlen':
                        result[m] = !validator.isLength(value, { max: param })
                        break;
                    case 'len':
                        const [min, max] = JSON.parse(param)
                        result[m] = !validator.isLength(value, { min, max })
                        break;
                    case 'min':
                        result[m] = !validator.isInt(value, { min: validator.toInt(param) })
                        break;
                    case 'max':
                        result[m] = !validator.isInt(value, { max: validator.toInt(param) })
                        break;
                    case 'list':
                        const list = JSON.parse(param)
                        result[m] = !validator.isIn(value, list)
                        break;
                    case 'mobileno':
                        const mobileRegex = /^\d{10}$/g;
                        result[m] = !validator.matches(value, mobileRegex);
                        break;
                    case 'pastdate':
                        const dateVal = moment(value).format("YYYY-MM-DD");
                        const currentDate = moment().format("YYYY-MM-DD");
                        if (moment(dateVal).isBefore(currentDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    case 'futuredate':
                        const valueFromDatePicker = moment(value).format("YYYY-MM-DD");
                        const todayDate = moment().format("YYYY-MM-DD");
                        if (moment(valueFromDatePicker).isAfter(todayDate)) {
                            result[m] = true;
                        } else {
                            result[m] = false;
                        }
                        break;
                    default:
                        throw new Error('Unrecognized validator.');
                }

            })
        }
        return result;
    }
}