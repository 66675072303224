import React, { useEffect, useState } from 'react';
import './PaymentList.scss';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box, Dialog, Card, CardContent } from '@material-ui/core';
import { http, FxInput, AlertService, FxCurrency, FxSelect } from '../../../fx-core';
import { useHistory } from "react-router-dom";
import ConfirmDialog from '../../../general/ConfirmDialog/ConfirmDialog';
import { general } from "../../../fx-core/helpers/generalService";
import { session } from "../../../fx-core/helpers/sessionService";

const PaymentList = () => {
    const history = useHistory();

    let defaultFilter: any = {
        partyId: -1,
        paymentTypeId: -1,
        paymentModeId: -1
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [payments, setPayments] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedPayment] = useState({});
    const [message, setMessage] = useState(null);
    const [parties, setParties] = useState([]);
    const [paymentMode, setPaymentMode] = useState([]);
    const [paymentType, setPaymentType] = useState([]);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getPayments({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getPayments(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getPayments = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            partyId: filter.partyId,
            paymentTypeId: filter.paymentTypeId,
            paymentModeId: filter.paymentModeId
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            if(options.filter.partyId) {
                filters.partyId = options.filter.partyId;
            }
            if(options.filter.paymentTypeId) {
                filters.paymentTypeId = options.filter.paymentTypeId;
            }
            if(options.filter.paymentModeId) {
                filters.paymentModeId = options.filter.paymentModeId;
            }
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `payment/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getPaymentsCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getPaymentsCallBack = (res, pagerOptions) => {
        const result = res.data;
        setPayments(result.items);
        computePagerData(result, pagerOptions);
    };

    const showPaymentForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/paymentform',
            state: stateObj
        });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Parties: { default: true },
                PaymentType: { default: true },
                PaymentMode: { default: true }
            },
            filters: {
                companyId: session.getItem("companyId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setParties(response.data.Parties);
            setPaymentType(response.data.PaymentType);
            setPaymentMode(response.data.PaymentMode);
        });
    }

    const confirmDelete = (payment: any) => {
        setSelectedPayment(payment);
        setMessage("Do you want to delete?");
        setOpen(true);
    };

    const confirmMessage = () => {
        setOpen(false);
        deletePayment(selectedItem);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPayment({});
    }

    const deletePayment = (item: any) => {
        let inputData = {
            id: item.id,
        };
        let apiOptions: any = {
            url: `payment/${inputData.id}`
        };
        http.delete(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            setSelectedPayment({});
            getPayments();
        })
    }

    useEffect(() => {
        initLookup();
        let options = { pager: { pageNo: 1 }, filter: filter };
        getPayments(options);
    }, []);

    return (
        <div className="content-container paymentlist-ui">
            <div className="title-font px-4 row justify-content-between">
                <span className="pt-1">Payments</span>
                {/* <span className="text-right add-ui" onClick={showPaymentForm}><i className="fas fa-plus-circle"></i></span> */}
            </div>

            {/* <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showItemForm}>Add</Button>
                    </span>
                </div>
            </div> */}

            <div className="px-2 col-sm-8 pl-3 mobileview">
                <div className="row col-12 mt-n1 pt-3 justify-content-between">
                    <div className="col-6 pr-0">
                        <FxSelect name="partyId" variant="outlined" label="Party Name"
                            options={parties}
                            selectedValue={filter.partyId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="col-6 pr-0">
                        <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                            options={paymentMode}
                            selectedValue={filter.paymentModeId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                </div>
                {
                    payments.map((payment, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row cardrow-ui">
                                        <span className="col-10">{payment.partyName}</span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-4">{general.getFormattedDate(payment.paymentDate)}</span>
                                        <span className="col-4"></span>
                                        {
                                            payment.paymentTypeId === 1 &&
                                            <span className="col-4 credit-currency">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={payment.paidAmount} />
                                            </span>
                                        }
                                        {
                                            payment.paymentTypeId === 2 &&
                                            <span className="col-4 debit-currency">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={payment.paidAmount} />
                                            </span>
                                        }
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-10">{payment.paymentMode}</span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-10">Notes : {payment.paymentNotes}</span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="px-2 col-sm-12 mx-auto pl-3 tabview">
                <div className="row col-12 mt-n1 pt-3 justify-content-between">
                    <div className="col-6 pr-0">
                        <FxSelect name="partyId" variant="outlined" label="Party Name"
                            options={parties}
                            selectedValue={filter.partyId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="col-6 pr-0">
                        <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                            options={paymentMode}
                            selectedValue={filter.paymentModeId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                </div>
                {
                    payments.map((payment, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row cardrow-ui">
                                        <span className="col-6">{payment.partyName}</span>
                                        <span className="col-3">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={payment.paidAmount} />
                                        </span>
                                    </div>
                                    <div className="row pt-1">
                                        <span className="col-3">{general.getFormattedDate(payment.paymentDate)}</span>
                                        <span className="col-3"></span>
                                        <span className="col-6">
                                            <div className="btn-style row justify-content-end pr-2">
                                                {/* <span className="pl-3 pt-1">
                                                    <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => showPaymentForm(payment)}>                                                            
                                                        Edit                                                            
                                                    </div>
                                                </span> */}
                                                <span className="pl-3 pt-1">
                                                    <div className="pb-1 px-2 btnstyle-ui pointer" onClick={() => confirmDelete(payment)}>
                                                        Delete
                                                    </div>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="row col-12 deskview">
                <div className="row col-12 mt-n1 pt-3 justify-content-between">
                    <div className="col-4 pr-0">
                        <FxSelect name="partyId" variant="outlined" label="Party Name"
                            options={parties}
                            selectedValue={filter.partyId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="col-4 pr-0">
                        <FxSelect name="paymentTypeId" variant="outlined" label="Payment Type"
                            options={paymentType}
                            selectedValue={filter.paymentTypeId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                    <div className="col-4 pr-0">
                        <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                            options={paymentMode}
                            selectedValue={filter.paymentModeId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>
                </div>
                <Container maxWidth="xl" className="mt-2">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head table-font">Party Name</TableCell>
                                    <TableCell className="table-head table-font">Payment Type</TableCell>
                                    <TableCell className="table-head table-font">Payment Date</TableCell>
                                    <TableCell className="table-head table-font">Amount</TableCell>
                                    <TableCell className="table-head table-font">Payment Mode</TableCell>
                                    <TableCell className="table-head table-font">Payment Notes</TableCell>
                                    <TableCell width="14%" className="table-head table-font">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    payments.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.partyName}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.paymentType}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {general.getFormattedDate(row.paymentDate)}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={row.paidAmount} />
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.paymentMode}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.paymentNotes}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="btn-style">
                                                    {/* <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => showPaymentForm(row)}> Edit
                                                        </Button>
                                                    </span> */}
                                                    <span className="table-font ml-3">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => confirmDelete(row)}> Delete
                                                        </Button>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </div>

            <div className="container pt-2 row col-12 justify-content-between mobileview">
                <div className="col-5 bottom-info pl-4">{pager.pagerInfo}</div>
                <div className="col-7 pr-0 mr-n4">
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={0}
                        paddingRight={0}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={2}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <div className="container pt-2 row col-12 justify-content-between tabview">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={0}
                    paddingRight={0}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            <div className="container pt-2 row col-12 justify-content-between deskview">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={1}
                    paddingRight={1}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} handleClose={handleClose} onConfirmCallback={confirmMessage} />
            </Dialog>
        </div>
    )
};

export default PaymentList;