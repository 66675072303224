import React, { useState, useEffect } from 'react';
import './ItemForm.scss';
import { FxInput, AlertService, http, FxSelect } from '../../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';

const ItemForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const itemId = propsObj.id;

    const defaultItem = {
        itemCode: "",
        itemName: "",
        hsnCode: "",
        measuringUnitId: -1,
        price: "",
        itemCategoryId: -1,
        taxPercentage: "",
        itemTypeId: -1,
        taxTypeId: -1,
        bagPerKG: "",
        id: 0
    };
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [measuringUnit, setMeasuringUnit] = useState([]);
    const [itemCategory, setItemCategory] = useState([]);
    const [itemType, setItemType] = useState([]);
    const [taxType, setTaxType] = useState([]);

    const validationMap = {
        itemCode: ["required"],
        itemName: ["required"],
        measuringUnitId: ["required"],
        price: ["required"],
        taxTypeId: ["required"],
        taxPercentage: ["required"],
        bagPerKG: ["required"]
    };

    const bulkValidate = () => {
        let records = [];
        for (var key in validationMap) {
            if (key === "price" || key === "taxPercentage" || key === "bagPerKG") {
                item[key] = item[key] === null ? "" : item[key] + '';
                let rec = { name: key, value: item[key], validations: validationMap[key] }
                records.push(rec);
            }
            else if (key === "measuringUnitId" || key === "taxTypeId") {
                let val = item[key] === -1 ? "" : item[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                records.push(rec);
            }
            else {
                let rec = { name: key, value: item[key], validations: validationMap[key] }
                records.push(rec);
            }
        }
        let validationResult = FormValidator.bulkValidate(records);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveItem = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `item/save`,
                data: item
            };
            http.post(apiOptions).then(res => {
                saveItemCallBack(res);
            });
        }
    };

    const saveItemCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (itemId > 0) {
            getItemById();
        }
        else {
            back();
        }
    };

    const getItemById = () => {
        let apiOptions: any = {
            url: `item/${itemId}`
        };
        http.get(apiOptions).then(res => {
            getItemByIdCallBack(res);
        });
    };

    const getItemByIdCallBack = (res) => {
        setItem(res.data);
    };

    const handleInputChange = (item) => {
        handleItemObjChange({ [item.name]: item.value });
        if (item.name === "measuringUnitId" || item.name === "taxTypeId") {
            item.value = item.value === -1 ? "" : item.value + '';
        }
        else if (item.name === "price" || item.name === "taxPercentage" || item.name === "bagPerKG") {
            item.value = item.value === null ? "" : item.value + '';
        }
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemObjChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                MeasuringUnit: { default: true },
                ItemCategory: { default: true },
                ItemType: { default: true },
                TaxType: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setMeasuringUnit(response.data.MeasuringUnit);
            setItemCategory(response.data.ItemCategory);
            setItemType(response.data.ItemType);
            setTaxType(response.data.TaxType)
        });
    }

    useEffect(() => {
        initLookup()
        if (itemId > 0) {
            getItemById();
        }
    }, []);

    return (
        <div className="content-container itemform-ui">
             <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Item</span>
            </div>
            <div className="col-sm-12 mx-auto row pt-3 mt-2">
                <div className="col-sm-4 mt-3">
                    <FxInput name="itemCode" variant="outlined" label="Item Code" size="small" fullWidth
                        value={item.itemCode}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("itemCode", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="itemName" variant="outlined" label="Item Name" size="small" fullWidth
                        value={item.itemName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("itemName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="hsnCode" variant="outlined" label="HSN No" size="small" fullWidth
                        value={item.hsnCode}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="price" variant="outlined" label="Price" size="small" fullWidth
                        value={item.price} type="number"
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("price", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="taxPercentage" variant="outlined" label="Tax Percentage" size="small" fullWidth
                        value={item.taxPercentage} type="number"
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("taxPercentage", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="measuringUnitId" variant="outlined" label="Measuring Unit"
                        options={measuringUnit}
                        selectedValue={item.measuringUnitId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("measuringUnitId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="bagPerKG" variant="outlined" label="Bag KG" size="small" fullWidth
                        value={item.bagPerKG} type="number"
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("bagPerKG", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="itemCategoryId" variant="outlined" label="Item Category"
                        options={itemCategory}
                        selectedValue={item.itemCategoryId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="itemTypeId" variant="outlined" label="Item Type"
                        options={itemType}
                        selectedValue={item.itemTypeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="taxTypeId" variant="outlined" label="Tax Type"
                        options={taxType}
                        selectedValue={item.taxTypeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("taxTypeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

            </div>
            <div className="row mt-4 pr-5 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Cancel</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveItem}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default ItemForm;