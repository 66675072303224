import moment from 'moment';
import { session }  from './sessionService';

export const general = {
    getFormattedDate: getFormattedDate,
    getFormattedTime: getFormattedTime,
    getImageUrl: getImageUrl,
    getCurrentDate: getCurrentDate,
    getConvertToDateTimeFormat: getConvertToDateTimeFormat
}

function getFormattedDate(date: any) {
    let formattedDate = moment(date).format("DD/MM/yyyy");
    return formattedDate;
}
function getFormattedTime(time: any) {
    let formattedTime = moment(time, 'hhmm').format('hh:mm A');
    return formattedTime;
}

function getImageUrl(imagePath:any) {
    if (imagePath) {
        let imagStorageUrl = session.getItem("imagStorageUrl");
        let result = imagStorageUrl + imagePath;
        return result;
    }
    return "";
}

function getCurrentDate() {
    return moment().format("DD/MM/YYYY")
}

function getConvertToDateTimeFormat(inputDate:any) {
    let result = moment(inputDate).format("YYYY-MM-DD HH:mm");
    return result;
}