import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/login/Login';
import CompanyList from './components/superadmin/CompanyList/CompanyList';
import CompanyForm from './components/superadmin/CompanyForm/CompanyForm';
import PartyList from './components/registration/party/PartyList/PartyList';
import PartyForm from './components/registration/party/PartyForm/PartyForm';
import CompanyBankDetailList from './components/registration/companybankdetail/CompanyBankDetailList/CompanyBankDetailList';
import CompanyBankDetailForm from './components/registration/companybankdetail/CompanyBankDetailForm/CompanyBankDetailForm';
import PartyBankDetailList from './components/registration/partybankdetail/PartyBankDetailList/PartyBankDetailList';
import PartyBankDetailForm from './components/registration/partybankdetail/PartyBankDetailForm/PartyBankDetailForm';
import ItemList from './components/billing/item/ItemList/ItemList';
import ItemForm from './components/billing/item/ItemForm/ItemForm';
import BillList from './components/billing/bill/BillList/BillList';
import BillForm from './components/billing/bill/BillForm/BillForm';
import PaymentList from './components/billing/payment/PaymentList/PaymentList';
import PaymentForm from './components/billing/payment/PaymentForm/PaymentForm';
import AddPayIn from './components/billing/payment/AddPayIn/AddPayIn';
import AddPayOut from './components/billing/payment/AddPayOut/AddPayOut';
import PurchaseForm from './components/billing/purchase/PurchaseForm/PurchaseForm';
import PurchaseList from './components/billing/purchase/PurchaseList/PurchaseList';
import Leftmenu from './components/leftmenu/Leftmenu';
import ChangePassword from './components/changepassword/ChangePassword';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import Header from './components/header/Header';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { ProgressProvider } from './components/fx-core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007d3b"
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    fontFamily: "sans-serif",
  },
});

export const App = () => {
  const hasLogin = window.location.pathname !== "/";
  console.log('window.location.pathname=', window.location.pathname);

  return (
    <MuiThemeProvider theme={theme}>
      <ProgressProvider>
        <Router>
          <Header />
          <div className="justify-content-center">
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <Route path="/companylist" component={CompanyList} />
              <Route path="/companyform" component={CompanyForm} />
              <Route path="/partylist" component={PartyList} />
              <Route path="/partyform" component={PartyForm} />
              <Route path="/companybankdetaillist" component={CompanyBankDetailList} />
              <Route path="/companybankdetailform" component={CompanyBankDetailForm} />
              <Route path="/partybankdetaillist" component={PartyBankDetailList} />
              <Route path="/partybankdetailform" component={PartyBankDetailForm} />
              <Route path="/itemlist" component={ItemList} />
              <Route path="/itemform" component={ItemForm} />
              <Route path="/billlist" component={BillList} />
              <Route path="/billform" component={BillForm} />
              <Route path="/paymentlist" component={PaymentList} />
              <Route path="/paymentform" component={PaymentForm} />
              <Route path="/addpayin" component={AddPayIn} />
              <Route path="/addpayout" component={AddPayOut} />
              <Route path="/purchaselist" component={PurchaseList} />
              <Route path="/purchaseform" component={PurchaseForm} />
              <Route path="/leftMenu" component={Leftmenu} />
              <Route path="/changepassword" component={ChangePassword} />
            </Switch>
          </div>
          <ToastContainer />
        </Router>
      </ProgressProvider>
    </MuiThemeProvider>
  );
}

export default App;
