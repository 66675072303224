import React, { useState, useEffect } from 'react';
import './PartyBankDetailForm.scss';
import { FxInput, FxSelect, AlertService, http } from '../../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';
import { session } from "../../../fx-core/helpers/sessionService";
import Checkbox from "@material-ui/core/Checkbox";

const PartyBankDetailForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const partyBankDetailId = propsObj.id;

    const defaultPartyBankDetail = {
        partyId: -1,
        bankName: "",
        accountNo: "",
        accountName: "",
        ifscCode: "",
        branchName: "",
        isDefault: false,
        id: 0
    };
    const [partyBankDetail, setPartyBankDetail] = useState(defaultPartyBankDetail);
    const [errors, setErrors] = useState({});
    const [parties, setParties] = useState([]);

    const validationMap = {
        bankName: ["required"],
        accountNo: ["required"],
        accountName: ["required"],
        ifscCode: ["required"],
        branchName: ["required"],
        partyId: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (key === "partyId") {
                let val = partyBankDetail[key] === -1 ? "" : partyBankDetail[key] + '';
                let item = { name: key, value: val, validations: validationMap[key] }
                items.push(item);
            }
            else {
                let item = { name: key, value: partyBankDetail[key], validations: validationMap[key] }
                items.push(item);
            }
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const savePartyBankDetail = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `party/bankdetail/save`,
                data: partyBankDetail
            };
            http.post(apiOptions).then(res => {
                savePartyBankDetailCallBack(res);
            });
        }
    };

    const savePartyBankDetailCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (partyBankDetailId > 0) {
            getPartyBankDetailById();
        }
        else {
            back();
        }
    };

    const getPartyBankDetailById = () => {
        let apiOptions: any = {
            url: `party/bankdetail/${partyBankDetailId}`
        };
        http.get(apiOptions).then(res => {
            getPartyBankDetailByIdCallBack(res);
        });
    };

    const getPartyBankDetailByIdCallBack = (res) => {
        setPartyBankDetail(res.data);
    };

    const handleInputChange = (item) => {
        handlePartyBankDetailObjChange({ [item.name]: item.value });
        if(item.name === "partyId") {
            item.value = item.value === -1 ? "" :  item.value + '';
        }
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleCheckbox = (event) => {
        handlePartyBankDetailObjChange({ [event.target.name]: event.target.checked });
    };    

    const handlePartyBankDetailObjChange = (data) => {
        setPartyBankDetail(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Parties: { default: true }
            },
            filters: {
                companyId: session.getItem("companyId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setParties(response.data.Parties);
        });
    }

    useEffect(() => {
        initLookup();
        if (partyBankDetailId > 0) {
            getPartyBankDetailById();
        }
    }, []);

    return (
        <div className="content-container partybankform-ui">
             <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Party Bank Detail</span>
            </div>
            <div className="col-sm-12 mx-auto row pt-3 mt-2">
                <div className="col-sm-4 mt-3">
                    <FxSelect name="partyId" variant="outlined" label="Party Name"
                        options={parties}
                        selectedValue={partyBankDetail.partyId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("partyId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="bankName" variant="outlined" label="Bank Name" size="small" fullWidth
                        value={partyBankDetail.bankName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("bankName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="accountNo" variant="outlined" label="Account No" size="small" fullWidth
                        value={partyBankDetail.accountNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("accountNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="accountName" variant="outlined" label="Account Name" size="small" fullWidth
                        value={partyBankDetail.accountName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("accountName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="ifscCode" variant="outlined" label="IFSC Code" size="small" fullWidth
                        value={partyBankDetail.ifscCode}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("ifscCode", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="branchName" variant="outlined" label="Branch Name" size="small" fullWidth
                        value={partyBankDetail.branchName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("branchName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <Checkbox name="isDefault" color="primary" checked={partyBankDetail.isDefault}
                        value={partyBankDetail.isDefault} onChange={handleCheckbox} />Is Default
                </div>

            </div>
            <div className="row mt-4 pr-5 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Cancel</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={savePartyBankDetail}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default PartyBankDetailForm;