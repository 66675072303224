import React, { useState, useEffect } from 'react';
import './PartyForm.scss';
import { FxInput, AlertService, http, FxSelect } from '../../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';
import Checkbox from "@material-ui/core/Checkbox";

const PartyForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const partyId = propsObj.id;

    const defaultParty = {
        partyCode: "",
        partyName: "",
        gstNo: "",
        gstTypeId: -1,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
        mobileNo: "",
        alternateNo: "",
        email: "",
        isFreezed: false,
        primaryContactName: "",
        id: 0
    };
    const [party, setParty] = useState(defaultParty);
    const [errors, setErrors] = useState({});
    const [gstTypes, setGstTypes] = useState([]);

    const validationMap = {
        partyCode: ["required"],
        partyName: ["required"],
        gstNo: ["required"],
        gstTypeId: ["required"],
        addressLine1: ["required"],
        district: ["required"],
        state: ["required"],
        country: ["required"],
        pincode: ["required"],
        mobileNo: ["required"],
        primaryContactName: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (party.id > 0 && key === "primaryContactName") {
                continue;
            }
            else if (key === "gstTypeId") {
                let val = party[key] === -1 ? "" : party[key] + '';
                let item = { name: key, value: val, validations: validationMap[key] }
                items.push(item);
            }
            else {
                let item = { name: key, value: party[key], validations: validationMap[key] }
                items.push(item);
            }
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveParty = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `party/save`,
                data: party
            };
            http.post(apiOptions).then(res => {
                savePartyCallBack(res);
            });
        }
    };

    const savePartyCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (partyId > 0) {
            getPartyById();
        }
        else {
            back();
        }
    };

    const getPartyById = () => {
        let apiOptions: any = {
            url: `party/${partyId}`
        };
        http.get(apiOptions).then(res => {
            getPartyByIdCallBack(res);
        });
    };

    const getPartyByIdCallBack = (res) => {
        setParty(res.data);
    };

    const handleInputChange = (item) => {
        handlePartyObjChange({ [item.name]: item.value });
        if (item.name === "gstTypeId") {
            item.value = item.value === -1 ? "" : item.value + '';
        }
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handlePartyObjChange = (data) => {
        setParty(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (event) => {
        handlePartyObjChange({ [event.target.name]: event.target.checked });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                GstType: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setGstTypes(response.data.GstType);
        });
    }

    useEffect(() => {
        initLookup();
        if (partyId > 0) {
            getPartyById();
        }
    }, []);

    return (
        <div className="content-container partyform-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Party</span>
            </div>
            <div className="col-sm-12 mx-auto row pt-3 mt-2">
                <div className="col-sm-4">
                    {
                        party.id === 0 &&
                        <div className="mt-3">
                            <FxInput name="partyCode" variant="outlined" label="Party Code" size="small" fullWidth
                                value={party.partyCode}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("partyCode", "required") &&
                                <div className="validation-msg">Field is required</div>
                            }
                        </div>
                    }
                    {
                        party.id > 0 &&
                        <div className="mt-3">
                            <FxInput name="partyCode" variant="outlined" label="Party Code" size="small" fullWidth
                                value={party.partyCode}
                                disabled={true}
                            />
                        </div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="partyName" variant="outlined" label="Party Name" size="small" fullWidth
                        value={party.partyName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("partyName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="gstNo" variant="outlined" label="GST No" size="small" fullWidth
                        value={party.gstNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("gstNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="gstTypeId" variant="outlined" label="GST Type"
                        options={gstTypes}
                        selectedValue={party.gstTypeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        fullWidth
                        onValueChange={handleInputChange} />
                    {
                        hasError("gstTypeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="addressLine1" variant="outlined" label="Address Line 1" size="small" fullWidth
                        value={party.addressLine1}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("addressLine1", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="addressLine2" variant="outlined" label="Address Line 2" size="small" fullWidth
                        value={party.addressLine2}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("addressLine2", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="addressLine3" variant="outlined" label="Address Line 3" size="small" fullWidth
                        value={party.addressLine3}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="district" variant="outlined" label="District" size="small" fullWidth
                        value={party.district}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("district", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="state" variant="outlined" label="State" size="small" fullWidth
                        value={party.state}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("state", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="country" variant="outlined" label="Country" size="small" fullWidth
                        value={party.country}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("country", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="pincode" variant="outlined" label="Pincode" size="small" fullWidth
                        value={party.pincode}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("pincode", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="mobileNo" variant="outlined" label="Mobile" size="small" fullWidth
                        value={party.mobileNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("mobileNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="alternateNo" variant="outlined" label="Alternate No" size="small" fullWidth
                        value={party.alternateNo}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="email" variant="outlined" label="Email" size="small" fullWidth
                        value={party.email}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-sm-4">
                    {
                        party.id === 0 &&
                        <div className="mt-3">
                            <FxInput name="primaryContactName" variant="outlined" label="Primary Contact Name" size="small" fullWidth
                                value={party.primaryContactName}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("primaryContactName", "required") &&
                                <div className="validation-msg">Field is required</div>
                            }
                        </div>
                    }
                    {
                        party.id > 0 &&
                        <div className="mt-3">
                            <FxInput name="primaryContactName" variant="outlined" label="Primary Contact Name" size="small" fullWidth
                                value={party.primaryContactName}
                                disabled={true}
                            />
                        </div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <Checkbox name="isFreezed" color="primary" checked={party.isFreezed}
                        value={party.isFreezed} onChange={handleCheckbox} />Is Freezed
                </div>
            </div>
            <div className="row pt-2 pr-5 justify-content-end pb-4">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Cancel</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveParty}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default PartyForm;