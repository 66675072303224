import React from 'react';
import NumberFormat from 'react-number-format';

export const FxCurrency = (props:any) => {
    
    const getCurrency = () => {
        let number = props.value;
        const currency = number.toFixed(2);
        return currency;
    }

    return(
        <NumberFormat
            value={getCurrency()}
            displayType={'text'}
            thousandSeparator={true}
            renderText={value => <span>{value}</span>}
        />        
    );

};