import React, { useState, useEffect } from 'react';
import './CompanyForm.scss';
import { FxInput, AlertService, http, FxSelect } from '../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';

const CompanyForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const companyId = propsObj.id;

    const defaultCompany = {
        companyCode: "",
        companyName: "",
        gstNo: "",
        gstTypeId: -1,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
        phoneNo: "",
        mobileNo: "",
        email: "",
        adminName: "",
        businessCategoryId: -1,
        id: 0
    };
    const [company, setCompany] = useState(defaultCompany);
    const [errors, setErrors] = useState({});
    const [businessCategories, setBusinessCategories] = useState([]);
    const [gstTypes, setGstTypes] = useState([]);

    const validationMap = {
        companyCode: ["required"],
        companyName: ["required"],
        gstNo: ["required"],
        gstTypeId: ["required"],
        addressLine1: ["required"],
        district: ["required"],
        state: ["required"],
        country: ["required"],
        pincode: ["required"],
        mobileNo: ["required"],
        email: ["required", "email"],
        adminName: ["required"],
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            if (company.id > 0 && key === "adminName") {
                continue;
            }
            else if (key === "gstTypeId") {
                let val = company[key] === -1 ? "" : company[key] + '';
                let item = { name: key, value: val, validations: validationMap[key] }
                items.push(item);
            }
            else {
                let item = { name: key, value: company[key], validations: validationMap[key] }
                items.push(item);
            }
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveCompany = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `company/save`,
                data: company
            };
            http.post(apiOptions).then(res => {
                saveCompanyCallBack(res);
            });
        }
    };

    const saveCompanyCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (companyId > 0) {
            getCompanyById();
        }
        else {
            back();
        }
    };

    const getCompanyById = () => {
        let apiOptions: any = {
            url: `company/${companyId}`
        };
        http.get(apiOptions).then(res => {
            getCompanyByIdCallBack(res);
        });
    };

    const getCompanyByIdCallBack = (res) => {
        setCompany(res.data);
    };

    const handleInputChange = (item) => {
        handleCompanyObjChange({ [item.name]: item.value });
        if (item.name === "gstTypeId") {
            item.value = item.value === -1 ? "" : item.value + '';
        }
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleCompanyObjChange = (data) => {
        setCompany(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                BusinessCategory: { default: true },
                GstType: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setBusinessCategories(response.data.BusinessCategory);
            setGstTypes(response.data.GstType);
        });
    }

    useEffect(() => {
        initLookup();
        if (companyId > 0) {
            getCompanyById();
        }
    }, []);

    return (
        <div className="content-container2 card margintop">
            <div className="title-font pl-4">
                <span>Company</span>
            </div>
            <div className="col-8 mx-auto row pt-3 pl-5 mt-2">
                <div className="col-4">
                    {
                        company.id === 0 &&
                        <div className="mt-3">
                            <FxInput name="adminName" variant="outlined" label="Admin Name" size="small"
                                value={company.adminName}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("adminName", "required") &&
                                <div className="validation-msg">Field is required</div>
                            }
                        </div>
                    }
                    {
                        company.id > 0 &&
                        <div className="mt-3">
                            <FxInput name="adminName" variant="outlined" label="Admin Name" size="small"
                                value={company.adminName}
                                disabled={true}
                            />
                        </div>
                    }
                </div>

                <div className="col-4">
                    {
                        company.id === 0 &&
                        <div className="mt-3">
                            <FxInput name="companyCode" variant="outlined" label="Company Code" size="small"
                                value={company.companyCode}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("companyCode", "required") &&
                                <div className="validation-msg">Field is required</div>
                            }
                        </div>
                    }
                    {
                        company.id > 0 &&
                        <div className="mt-3">
                            <FxInput name="companyCode" variant="outlined" label="Company Code" size="small"
                                value={company.companyCode}
                                disabled={true}
                            />
                        </div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="companyName" variant="outlined" label="Company Name" size="small"
                        value={company.companyName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("companyName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="gstNo" variant="outlined" label="GST No" size="small"
                        value={company.gstNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("gstNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxSelect name="gstTypeId" variant="outlined" label="GST Type"
                        options={gstTypes}
                        selectedValue={company.gstTypeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("gstTypeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="addressLine1" variant="outlined" label="Address Line 1" size="small"
                        value={company.addressLine1}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("addressLine1", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="addressLine2" variant="outlined" label="Address Line 2" size="small"
                        value={company.addressLine2}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("addressLine2", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="addressLine3" variant="outlined" label="Address Line 3" size="small"
                        value={company.addressLine3}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="district" variant="outlined" label="District" size="small"
                        value={company.district}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("district", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="state" variant="outlined" label="State" size="small"
                        value={company.state}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("state", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="country" variant="outlined" label="Country" size="small"
                        value={company.country}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("country", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="pincode" variant="outlined" label="Pincode" size="small"
                        value={company.pincode}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("pincode", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="phoneNo" variant="outlined" label="Phone" size="small"
                        value={company.phoneNo}
                        onValueChange={handleInputChange}
                    />
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="mobileNo" variant="outlined" label="Mobile" size="small"
                        value={company.mobileNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("mobileNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxInput name="email" variant="outlined" label="Email" size="small"
                        value={company.email}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("email", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                    {
                        hasError("email", "email") &&
                        <div className="validation-msg">Please enter valid email id</div>
                    }
                </div>

                <div className="col-4 mt-3">
                    <FxSelect name="businessCategoryId" variant="outlined" label="Business Category"
                        options={businessCategories}
                        selectedValue={company.businessCategoryId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
            </div>
            <div className="row mt-4 pr-5 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Back</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveCompany}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default CompanyForm;