import React from 'react';
import './Leftmenu.css';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import AssignmentReturnedSharpIcon from '@material-ui/icons/AssignmentReturnedSharp';
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconExitToApp from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import IconList from '@material-ui/icons/List';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import { useLocation } from "react-router-dom";
import { session } from "../fx-core/helpers/sessionService";

const Leftmenu = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  
  if (location.pathname == '/' || location.pathname == '/login') {
    return <></>;
  } else {
    let menus: any = [];
    const roleCode = session.getItem("roleCode");
    if(roleCode == "SUPERADMIN") {
      menus = [
        { title: 'Companies', route: '/companylist', icon: <IconList /> },
        { title: 'Logout', route: '/', icon: <IconExitToApp /> }
      ];      
    }
    else {
      menus = [
        { title: 'Parties', route: '/partylist', icon: <PeopleIcon /> },
        { title: 'Company Bank Details', route: '/companybankdetaillist', icon: <AccountBalanceIcon /> },
        { title: 'Party Bank Details', route: '/partybankdetaillist', icon: <AccountBalanceIcon /> },
        { title: 'Items', route: '/itemlist', icon: <IconList /> },
        { title: 'Bills', route: '/billlist', icon: <ReceiptIcon /> },
        { title: 'Purchases', route: '/purchaselist', icon: <AssignmentReturnedSharpIcon /> },
        { title: 'Payments', route: '/paymentlist', icon: <AttachMoneySharpIcon /> },
        { title: 'Change Password', route: '/changepassword', icon: <VpnKeySharpIcon /> },
        { title: 'Logout', route: '/', icon: <IconExitToApp /> }
      ];
    }

    const openMenu = (menu) => {
      if (menu.title = "Logout") {
        localStorage.clear();
      }
      history.push(menu.route);
    }

    return (
      <List className={classes.menuheight} disablePadding>
        { menus.map((menu: any, i) => (
          <ListItem key={i} button className={classes.menuItem} selected={true} onClick={() => openMenu(menu)}>
            <ListItemIcon className={classes.menuItemIcon}>
              {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.title} />
          </ListItem>
        ))
        }
      </List>
    );
  }
}

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    menuheight: {
      background: '#007d3b',
      color: '#ffffff',
      width: drawerWidth,
      height: 690,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#ffffff',
    }
  }),
)

export default Leftmenu;