import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { matches, keys } from '../keyboard';
import { ButtonKinds } from '../tools/button-kinds';
import uid from '../tools/uniqueId';

const prefix = "bx";

function noop() { }

export function FxAttachmentButton({
  accept,
  buttonKind = 'primary',
  className,
  disabled = false,
  disableLabelChanges = false,
  id,
  labelText: ownerLabelText = 'Add file',
  multiple = false,
  onChange = noop,
  role = 'button',
  name,
  size,
  tabIndex = 0,
  ...other
}: any) {
  const [labelText, setLabelText] = useState(ownerLabelText);
  const [prevOwnerLabelText, setPrevOwnerLabelText] = useState(ownerLabelText);
  const { current: inputId } = useRef(id || uid());
  const inputNode:any = useRef(null);
  const classes = cx(`${prefix}--btn`, className, {
    [`${prefix}--btn--${buttonKind}`]: buttonKind,
    [`${prefix}--btn--disabled`]: disabled,
    [`${prefix}--btn--field`]: size === 'field',
    [`${prefix}--btn--sm`]: size === 'small',
  });

  // Adjust label text state based on changes to the labelText prop
  if (ownerLabelText !== prevOwnerLabelText) {
    setLabelText(ownerLabelText);
    setPrevOwnerLabelText(ownerLabelText);
  }

  function onClick(event:any) {
    event.target.value = null;
  }

  function onKeyDown(event:any) {
    if (matches(event, [keys.Enter, keys.Space])) {
      inputNode.current.click();
    }
  }

  function handleOnChange(event:any) {
    const files = event.target.files;
    const length = event.target.files.length;
    if (files && !disableLabelChanges) {
      if (length > 1) {
        setLabelText(`${length} files`);
      } else if (length === 1) {
        setLabelText(files[0].name);
      }
    }
    onChange(event);
  }

  return (
    <>
      <label
        tabIndex={disabled ? -1 : tabIndex || 0}
        aria-disabled={disabled}
        className={classes}
        onKeyDown={onKeyDown}
        htmlFor={inputId}
        {...other}>
        <span role={role}>{labelText}</span>
      </label>
      <input
        className={`${prefix}--visually-hidden`}
        ref={inputNode}
        id={inputId}
        disabled={disabled}
        type="file"
        tabIndex={-1}
        multiple={multiple}
        accept={accept}
        name={name}
        onChange={handleOnChange}
        onClick={onClick}
      />
    </>
  );
}

FxAttachmentButton.propTypes = {
  /**
   * Specify the types of files that this input should be able to receive
   */
  accept: PropTypes.arrayOf(PropTypes.string),

  /**
   * Specify the type of underlying button
   */
  buttonKind: PropTypes.oneOf(ButtonKinds),

  /**
   * Provide a custom className to be applied to the container node
   */
  className: PropTypes.string,

  /**
   * Specify whether you want to disable any updates to the FxAttachmentButton
   * label
   */
  disableLabelChanges: PropTypes.bool,

  /**
   * Specify whether file input is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Provide a unique id for the underlying <input> node
   */
  id: PropTypes.string,

  /**
   * Provide the label text to be read by screen readers when interacting with
   * this control
   */
  labelText: PropTypes.node,

  /**
   * Specify whether you want the component to list the files that have been
   * submitted to be uploaded
   */
  listFiles: PropTypes.bool,

  /**
   * Specify if the component should accept multiple files to upload
   */
  multiple: PropTypes.bool,

  /**
   * Provide a name for the underlying <input> node
   */
  name: PropTypes.string,

  /**
   * Provide an optional `onChange` hook that is called each time the <input>
   * value changes
   */
  onChange: PropTypes.func,

  /**
   * Provide an optional `onClick` hook that is called each time the button is
   * clicked
   */
  onClick: PropTypes.func,

  /**
   * Provide an accessibility role for the <FxAttachmentButton>
   */
  role: PropTypes.string,

  /**
   * Specify the size of the button, from a list of available sizes.
   * For `default` buttons, this prop can remain unspecified.
   */
  size: PropTypes.oneOf(['default', 'field', 'small']),

  /**
   * Provide a custom tabIndex value for the <FxAttachmentButton>
   */
  tabIndex: PropTypes.number,
};
