import React, { useState, useEffect } from 'react';
import './AddPayIn.scss';
import { FxInput, AlertService, http, FxSelect, FxDate } from '../../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';
import { general } from "../../../fx-core/helpers/generalService";
import { session } from "../../../fx-core/helpers/sessionService";

const AddPayIn = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const bill = propsObj;

    const defaultItem = {
        partyId: bill.partyId,
        paymentDate: general.getCurrentDate(),
        paidAmount: bill.dueAmount,
        paymentModeId: -1,
        paymentNotes: "",
        id: 0,
        paymentTypeId: 1, // 1 - Credit, 2 - Debit
        billId: bill.id,
        purchaseId: -1
    };
    const [payment, setPayment] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [parties, setParties] = useState([]);
    const [paymentMode, setPaymentMode] = useState([]);

    const validationMap = {
        partyId: ["required"],
        paymentDate: ["required"],
        paidAmount: ["required"],
        paymentModeId: ["required"]
    };

    const bulkValidate = () => {
        let records = [];
        for (var key in validationMap) {
            if (key === "paymentDate" || key === "paidAmount" || key === "bagPerKG") {
                payment[key] = payment[key] === null ? "" : payment[key] + '';
                let rec = { name: key, value: payment[key], validations: validationMap[key] }
                records.push(rec);
            }
            else if (key === "partyId" || key === "paymentModeId") {
                let val = payment[key] === -1 ? "" : payment[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                records.push(rec);
            }
            else {
                let rec = { name: key, value: payment[key], validations: validationMap[key] }
                records.push(rec);
            }
        }
        let validationResult = FormValidator.bulkValidate(records);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const savePayment = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `payment/addpayin`,
                data: payment
            };
            http.post(apiOptions).then(res => {
                savePaymentCallBack(res);
            });
        }
    };

    const savePaymentCallBack = (res) => {
        AlertService.showSuccessMsg();
        back();
    };

    const handleInputChange = (item) => {
        handleItemObjChange({ [item.name]: item.value });
        if (item.name === "partyId" || item.name === "paymentModeId") {
            item.value = item.value === -1 ? "" : item.value + '';
        }
        else if (item.name === "paidAmount") {
            item.value = item.value === null ? "" : item.value + '';
        }
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemObjChange = (data) => {
        setPayment(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Parties: { default: true },
                PaymentMode: { default: true }
            },
            filters: {
                companyId: session.getItem("companyId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setParties(response.data.Parties);
            setPaymentMode(response.data.PaymentMode);
        });
    }

    useEffect(() => {
        initLookup()

    }, []);

    return (
        <div className="content-container addpayin-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Pay In</span>
            </div>
            <div className="col-sm-12 mx-auto row pt-3 mt-2">

                <div className="col-sm-4 mt-3">
                    <FxInput name="partyName" variant="outlined" label="Party Name" size="small" fullWidth
                        value={bill.partyName}
                        disabled={true}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxDate name="paymentDate" variant="outlined" label="Payment Date" size="small" fullWidth
                        value={payment.paymentDate}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("billNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="paidAmount" variant="outlined" label="Amount" size="small" fullWidth
                        value={payment.paidAmount} type="number"
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("price", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="paymentModeId" variant="outlined" label="Payment Mode"
                        options={paymentMode}
                        selectedValue={payment.paymentModeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("paymentModeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>
                <div className="col-sm-4 mt-3">
                    <FxInput name="paymentNotes" variant="outlined" label="Payment Notes" size="small" fullWidth
                        value={payment.paymentNotes}
                        onValueChange={handleInputChange}
                    />
                </div>

            </div>
            <div className="row mt-4 pr-5 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Cancel</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={savePayment}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default AddPayIn;