import React, { useEffect, useState } from 'react';
import './PartyList.scss';
import Pagination from '@material-ui/lab/Pagination';
import IconPhone from '@material-ui/icons/Phone';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Box, Card, CardContent, AppBar, Toolbar } from '@material-ui/core';
import { http, FxInput, FxCurrency } from '../../../fx-core';
import { useHistory } from "react-router-dom";

const PartyList = () => {
    const history = useHistory();

    let defaultFilter: any = {
        searchText: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [parties, setParties] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getParties({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getParties(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getParties = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            codeorname: filter.searchText
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filters.codeorname = options.filter.searchText;
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `party/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getPartiesCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getPartiesCallBack = (res, pagerOptions) => {
        const result = res.data;
        setParties(result.items);
        computePagerData(result, pagerOptions);
    };

    const showPartyForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/partyform',
            state: stateObj
        });
    };

    useEffect(() => {
        let options = { pager: { pageNo: 1 }, filter: filter };
        getParties(options);
    }, []);

    return (
        <div className="content-container partylist-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Parties</span>
                <span className="text-right add-ui" onClick={showPartyForm}><i className="fas fa-plus-circle"></i></span>
            </div>

            <div className="row col-12 mt-n1 pt-3 justify-content-between">
                <div className="col-sm-3 search mt-n1 pl-1">
                    <FxInput name="searchText" variant="outlined" label="Search Code / Name" fullWidth
                        size="small"
                        value={filter.searchText}
                        onValueChange={handleInputChange}
                    />
                </div>
            </div>
            {/* <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showPartyForm}>Add</Button>
                    </span>
                </div>
            </div> */}
            <div className="px-2 pt-2 col-sm-8 pl-3 mobileview">
                {
                    parties.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row cardrow-ui">
                                        <span className="col-8">{item.partyName}</span>
                                        <span className="col-4 text-right">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.dueAmount ? item.dueAmount : 0} />
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="col-6">{item.gstNo}</span>
                                        <span className="col-6 text-right">
                                            <Link href={'tel:' + item.mobileNo}>
                                                <IconPhone fontSize="small" />
                                                <span>{item.mobileNo}</span>
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="row pt-1 pr-2">
                                        <span className="col-6">{item.email}</span>
                                        <span className="col-4"></span>
                                        <span className="col-2 px-1 text-center">
                                            <div className="pb-1 btnstyle-ui pointer" onClick={() => showPartyForm(item)}>
                                                Edit
                                            </div>
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="px-4 pt-2 col-sm-12 pl-3 tabview">
                {
                    parties.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row cardrow-ui">
                                        <span className="col-8">{item.partyName}</span>
                                        <span className="col-4 text-right">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={item.dueAmount ? item.dueAmount : 0} />
                                        </span>
                                    </div>
                                    <div className="row">
                                        <span className="col-6">{item.gstNo}</span>
                                        <span className="col-6 text-right">
                                            <Link href={'tel:' + item.mobileNo}>
                                                <IconPhone fontSize="small" />
                                                <span>{item.mobileNo}</span>
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="row pt-1 pr-2">
                                        <span className="col-6">{item.email}</span>
                                        <span className="col-4"></span>
                                        <span className="col-2 px-1 text-center">
                                            <div className="pb-1 btnstyle-ui pointer" onClick={() => showPartyForm(item)}>
                                                Edit
                                            </div>
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="row col-12 deskview">
                <Container maxWidth="xl" className="mt-2">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head table-font">Code</TableCell>
                                    <TableCell className="table-head table-font">Name</TableCell>
                                    <TableCell className="table-head table-font">GST No</TableCell>
                                    <TableCell className="table-head table-font">Mobile</TableCell>
                                    <TableCell className="table-head table-font">Alternate No</TableCell>
                                    <TableCell className="table-head table-font">Email</TableCell>
                                    <TableCell className="table-head table-font">Due Amount</TableCell>
                                    <TableCell width="14%" className="table-head table-font">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    parties.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.partyCode}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.partyName}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.gstNo}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="mobileno-color table-font">
                                                        <Link href={'tel:' + row.mobileNo}>
                                                            <IconPhone fontSize="small" />
                                                            <span>{row.mobileNo}</span>
                                                        </Link>
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.alternateNo}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div> <span className="table-font">{row.email}</span></div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={row.dueAmount ? row.dueAmount : 0} />
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="btn-style">
                                                    <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => showPartyForm(row)}> Edit
                                                    </Button>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </div>

            <div className="container pt-2 row col-12 justify-content-between mobileview">
                <div className="col-5 bottom-info pl-4">{pager.pagerInfo}</div>
                <div className="col-7 pr-0 mr-n4">
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                        padding={0}
                        paddingRight={0}
                    >
                        <Pagination
                            page={pager.pageNo}
                            count={pager.pageCount}
                            shape="rounded"
                            color="primary"
                            showFirstButton
                            showLastButton
                            boundaryCount={1}
                            onChange={pageChanged}
                        />
                    </Box>
                </div>
            </div>
            <div className="container pt-2 row col-12 justify-content-between tabview">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={0}
                    paddingRight={0}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            <div className="container pt-2 row col-12 justify-content-between deskview">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={1}
                    paddingRight={1}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
        </div>
    )
};

export default PartyList;