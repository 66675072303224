import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import './Login.css';
import { useHistory } from "react-router-dom";
import { BorderAll, StayCurrentPortraitTwoTone } from '@material-ui/icons';
import { FxInput, http, session } from '../fx-core';
//import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    margin: `${theme.spacing(0)} auto`
  },
  loginBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    background: '#007d3b'
  },
  header: {
    textAlign: 'center',
    background: '#007d3b',
    color: '#ff'
  },
  card: {
    marginTop: theme.spacing(15),
  }
}));


const Login = () => {

  const classes = useStyles();
  const history = useHistory();
  const userName = useRef(null);
  const password = useRef(null);
  //const [error, setError] = useState(null);
  const [valError, setValError] = useState(null);

  let [loading, setLoading] = useState(false);

  let defaultFormModel: any = {
    userName: '',
    passowrd: ''
  };
  const [formModel, setFormModel] = useState(defaultFormModel);

  const handleInputChange = (item) => {
    handleFormModelChange({ [item.name]: item.value });
  }

  const handleFormModelChange = (data) => {
    setFormModel(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleLogin = () => {
    setValError(null);
    if (!formModel.userName || !formModel.password) {
      setValError("Username/Password is required");
      return;
    }
    let inputData = { UserName: formModel.userName, Password: formModel.password };
    let apiOptions = {
      url: 'security/authenticate/password',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      let result = response.data;
      let authToken = result.authToken;

      session.setItem('id_token', "token " + authToken.sessionId);
      session.setItem('roleCode', authToken.roleCode);
      session.setItem('userId', authToken.userId);
      session.setItem('companyId', authToken.companyId);
      session.setItem('email', authToken.email);
      session.setItem('imagStorageUrl', result.config.imagStorageUrl);

      if (authToken.roleCode === 'SUPERADMIN') {
        history.push('/companylist');
      } else if (authToken.roleCode === 'ADMIN') {
        history.push('/partylist');
      }
      else {
        alert('You does not have permission for this app!');
      }
    });
  }

  const body = (
    <form className={classes.container} noValidate>
      <Card className={classes.card} variant="outlined">
        <div className="login-row">
          <div className="logo pt-2">
            {/* <img src={logo} alt="Logo" /> */}
          </div>
          <div>
            <h2 className="align-name pt-4">Account Books</h2>
          </div>
        </div>
        <CardContent>
          <div>
            <FxInput name="userName" variant="standard" label="Username"
              fullWidth
              value={formModel.userName}
              onValueChange={handleInputChange} />

            <FxInput name="password" variant="standard" label="Password"
              fullWidth
              type="password"
              value={formModel.password}
              onValueChange={handleInputChange} />
          </div>
        </CardContent>
        {valError &&
          <div className="error"> {valError} </div>}
        {/* {error &&
          <div className="error"> {error} </div>} */}
        <CardActions>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className={classes.loginBtn}
            onClick={handleLogin}
          >
            Login
        </Button>
        </CardActions>
      </Card>
    </form>
  );

  return (
    <div>
      {body}
      {/* <div className="sweet-loading">
        <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
        <ClipLoader color="red" loading={loading} size={30} />
      </div> */}
    </div>
  );

}
export default Login;
