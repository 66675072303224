import React, { useState, useEffect } from 'react';
import './CompanyBankDetailForm.scss';
import { FxInput, AlertService, http } from '../../../fx-core';
import { Button } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';
import Checkbox from "@material-ui/core/Checkbox";

const CompanyBankDetailForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const companyBankDetailId = propsObj.id;

    const defaultCompanyBankDetail = {
        bankName: "",
        accountNo: "",
        accountName: "",
        ifscCode: "",
        branchName: "",
        isDefault: false,
        id: 0
    };
    const [companyBankDetail, setCompanyBankDetail] = useState(defaultCompanyBankDetail);
    const [errors, setErrors] = useState({});

    const validationMap = {
        bankName: ["required"],
        accountNo: ["required"],
        accountName: ["required"],
        ifscCode: ["required"],
        branchName: ["required"]
    };

    const bulkValidate = () => {
        let items = [];
        for (var key in validationMap) {
            let item = { name: key, value: companyBankDetail[key], validations: validationMap[key] }
            items.push(item);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveCompanyBankDetail = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions: any = {
                url: `company/bankdetail/save`,
                data: companyBankDetail
            };
            http.post(apiOptions).then(res => {
                saveCompanyBankDetailCallBack(res);
            });
        }
    };

    const saveCompanyBankDetailCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (companyBankDetailId > 0) {
            getCompanyBankDetailById();
        }
        else {
            back();
        }
    };

    const getCompanyBankDetailById = () => {
        let apiOptions: any = {
            url: `company/bankdetail/${companyBankDetailId}`
        };
        http.get(apiOptions).then(res => {
            getCompanyBankDetailByIdCallBack(res);
        });
    };

    const getCompanyBankDetailByIdCallBack = (res) => {
        setCompanyBankDetail(res.data);
    };

    const handleInputChange = (item) => {
        handleCompanyBankDetailObjChange({ [item.name]: item.value });
        item.validations = validationMap[item.name];
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleCheckbox = (event) => {
        handleCompanyBankDetailObjChange({ [event.target.name]: event.target.checked });
    };

    const handleCompanyBankDetailObjChange = (data) => {
        setCompanyBankDetail(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    useEffect(() => {
        if (companyBankDetailId > 0) {
            getCompanyBankDetailById();
        }
    }, []);

    return (
        <div className="content-container bankdetailform-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Company Bank Detail</span>
            </div>
            <div className="col-sm-12 mx-auto row pt-3 mt-2">
                <div className="col-sm-4 mt-3">
                    <FxInput name="bankName" variant="outlined" label="Bank Name" size="small" fullWidth
                        value={companyBankDetail.bankName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("bankName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="accountNo" variant="outlined" label="Account No" size="small" fullWidth
                        value={companyBankDetail.accountNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("accountNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="accountName" variant="outlined" label="Account Name" size="small" fullWidth
                        value={companyBankDetail.accountName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("accountName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="ifscCode" variant="outlined" label="IFSC Code" size="small" fullWidth
                        value={companyBankDetail.ifscCode}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("ifscCode", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="branchName" variant="outlined" label="Branch Name" size="small" fullWidth
                        value={companyBankDetail.branchName}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("branchName", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <Checkbox name="isDefault" color="primary" checked={companyBankDetail.isDefault}
                        value={companyBankDetail.isDefault} onChange={handleCheckbox} />Is Default
                </div>

            </div>
            <div className="row mt-4 pr-5 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Cancel</Button>
                </span>
                <span className="pl-2">
                    <Button variant="contained" size="small" color="primary" onClick={saveCompanyBankDetail}>Save</Button>
                </span>
            </div>
        </div>
    )
};

export default CompanyBankDetailForm;