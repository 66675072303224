import React, { useEffect, useState } from 'react';
import './CompanyList.scss';
import Pagination from '@material-ui/lab/Pagination';
import IconPhone from '@material-ui/icons/Phone';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Box } from '@material-ui/core';
import { http, FxInput, AlertService } from '../../fx-core';
import { useHistory } from "react-router-dom";

const CompanyList = () => {
    const history = useHistory();

    let defaultFilter: any = {
        searchText: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };

    const [companies, setCompanies] = useState([]);
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getCompanies({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getCompanies(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getCompanies = (options: any = {}) => {
        let startIndex = 0;
        let filters = {
            codeorname: filter.searchText
        };

        if (options && options.pager) {
            startIndex = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            filters.codeorname = options.filter.searchText;
        }

        let inputData = {
            filters,
            startIndex,
            pageSize: pager.pageSize,
        };
        let apiOptions: any = {
            url: `company/superadmin/list`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            getCompaniesCallBack(res, { startIndex, ...options.pager });
        })
    };

    const getCompaniesCallBack = (res, pagerOptions) => {
        const result = res.data;
        setCompanies(result.items);
        computePagerData(result, pagerOptions);
    };

    const showCompanyForm = (item) => {
        let stateObj: any = { id: 0 };
        if (item && item.id) {
            stateObj = { ...stateObj, ...item }
        }
        history.push({
            pathname: '/companyform',
            state: stateObj
        });
    };


    const updateIsActive = (row) => {
        let apiOptions: any = {
            url: `company/updateisactive/${row.id}`,
            data: {}
        };
        http.put(apiOptions).then(res => {
            updateIsActiveCallBack(res);
        });
    };

    const updateIsActiveCallBack = (res) => {
        AlertService.showSuccessMsg();
        let options = { filter: filter };
        getCompanies(options);
    };

    useEffect(() => {
        let options = { pager: { pageNo: 1 }, filter: filter };
        getCompanies(options);
    }, []);

    return (
        <div className="content-container">
            <div className="title-font mt-n3 py-1 pl-2">
                <span>Companies</span>
            </div>
            <div className="row col-12 mt-n1 pt-3 justify-content-between">
                <div className="col-4 search mt-n1 pl-1">
                    <FxInput name="searchText" variant="outlined" label="Search Code / Name"
                        size="small"
                        value={filter.searchText}
                        onValueChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showCompanyForm}>Add</Button>
                    </span>
                </div>
            </div>
            <div className="row col-12">
                <Container maxWidth="xl" className="mt-2">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head table-font">Code</TableCell>
                                    <TableCell className="table-head table-font">Name</TableCell>
                                    <TableCell className="table-head table-font">GST No</TableCell>
                                    <TableCell className="table-head table-font">Phone No</TableCell>
                                    <TableCell className="table-head table-font">Mobile</TableCell>
                                    <TableCell className="table-head table-font">Email</TableCell>
                                    <TableCell width="14%" className="table-head table-font">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    companies.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.companyCode}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.companyName}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.gstNo}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    {row.phoneNo}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="mobileno-color table-font">
                                                        <Link href={'tel:' + row.mobileNo}>
                                                            <IconPhone fontSize="small" />
                                                            <span>{row.mobileNo}</span>
                                                        </Link>
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div> <span className="table-font">{row.email}</span></div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="btn-style">
                                                    <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => showCompanyForm(row)}> Edit
                                                        </Button>
                                                    </span>
                                                    <span className="table-font ml-1">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => updateIsActive(row)}> {row.isActive ? "InActive" : "Active"}
                                                        </Button>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </div>
            <div className="container pt-2 row col-12 justify-content-between">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={1}
                    paddingRight={1}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
        </div>
    )
};

export default CompanyList;