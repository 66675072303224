import PropTypes from 'prop-types';
import React from 'react';
import { Loading } from '../loading/Loading';

import DoneIcon from '@material-ui/icons/Done';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const prefix = 'bx';

export function Filename({ iconDescription, status, invalid, ...rest }: any) {
    switch (status) {
        case 'uploading':
            return <Loading description={iconDescription} small withOverlay={false} />;
        case 'edit':
            return (
                <>
                    {invalid && <ReportProblemIcon />}
                    <button aria-label={iconDescription} className={`${prefix}--file-close`} type="button" {...rest}>
                        <HighlightOffIcon />
                    </button>
                </>
            );
        case 'complete':
            return <DoneIcon />;
        default:
            return null;
    }
}
Filename.propTypes = {
    iconDescription: PropTypes.string,
    invalid: PropTypes.bool,
    status: PropTypes.oneOf(['edit', 'complete', 'uploading']),
    tabIndex: PropTypes.string,
    onKeyDown: PropTypes.func,
    onClick: PropTypes.func,
};

Filename.defaultProps = {
    iconDescription: 'Uploading file',
    status: 'uploading',
    tabIndex: '0',
};
