import React, { useState } from 'react';
import './Header.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useLocation } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Leftmenu from '../leftmenu/Leftmenu';

const Header = () => {
  let location = useLocation();
 
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  
 
  if (location.pathname == '/' || location.pathname == '/login') {
    return <></>;
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <AppBar>
            <Toolbar>
              {/* <div className="logo">
                <img src={logo} alt="Logo" />
              </div> */}
              <IconButton
               color="inherit"
               aria-label="open drawer"
               onClick={toggleDrawer}
               edge="start">
                <MenuRoundedIcon />
              </IconButton>
              
              <div className="col-7">
                <h2 className="titlestyle">Account Books</h2>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer variant="persistent" anchor="left" className="drawer-leftmenu" open={open}>
              <span onClick={toggleDrawer}>
                <Leftmenu />
              </span>
          </Drawer>
        </div>
      </div>
    );
  }
}
export default Header;