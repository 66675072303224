import React, { useState, useEffect } from 'react';
import './BillForm.scss';
import { FxInput, AlertService, http, FxSelect, FxCurrency, FxDate } from '../../../fx-core';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Card, CardContent } from '@material-ui/core';
import { FormValidator } from '../../../../utils/FormValidator';
import { useHistory } from 'react-router-dom';
import { session } from "../../../fx-core/helpers/sessionService";
import { general } from "../../../fx-core/helpers/generalService";
import { nanoid } from 'nanoid';

const BillForm = (props) => {
    const history = useHistory();
    const propsObj = props.location.state;
    const billId = propsObj.id;

    const defaultBill = {
        partyId: -1,
        billNo: "",
        billDate: billId > 0 ? "" : general.getCurrentDate(),
        transportModeId: -1,
        veichleNo: "",
        billTypeId: -1,
        subTotal: 0,
        total: 0,
        isIntraState: false,
        partyName: "",
        cgstAmount: 0,
        sgstAmount: 0,
        id: 0
    };

    const defaultItem = {
        itemCode: "",
        itemName: "",
        hsnCode: "",
        measuringUnitId: -1,
        price: 0,
        itemCategoryId: -1,
        taxPercentage: 0,
        itemTypeId: -1,
        taxTypeId: -1,
        bagPerKG: 0,
        measuringUnit: "",
        id: 0,
    };

    const [bill, setBill] = useState(defaultBill);
    const [billDetails, setBillDetails] = useState([]);
    const [errors, setErrors] = useState({});
    const [parties, setParties] = useState([]);
    const [transportMode, setTransportMode] = useState([]);
    const [billType, setBillType] = useState([]);
    const [items, setItems] = useState([]);
    const [selItemId, setSelItemId] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(defaultItem);
    const [isNotApproved, setIsNotApproved] = useState(true);
    const [nextBillNo, setNextBillNo] = useState(0);

    const validationMap = {
        partyId: ["required"],
        billNo: ["required"],
        transportModeId: ["required"],
        veichleNo: ["required"],
        billTypeId: ["required"],
        billDate: ["required"]
    };

    const bulkValidate = () => {
        let records = [];
        for (var key in validationMap) {
            if (key === "billNo") {
                bill[key] = bill[key] === null ? "" : bill[key] + '';
                let rec = { name: key, value: bill[key], validations: validationMap[key] }
                records.push(rec);
            }
            else if (key === "partyId" || key === "transportModeId" || key === "billTypeId") {
                let val = bill[key] === -1 ? "" : bill[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                records.push(rec);
            }
            else {
                let rec = { name: key, value: bill[key], validations: validationMap[key] }
                records.push(rec);
            }
        }
        let validationResult = FormValidator.bulkValidate(records);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveBill = () => {
        const isValid = bulkValidate();
        if (isValid) {
            bill.billDate = general.getConvertToDateTimeFormat(bill.billDate);
            let apiOptions: any = {
                url: `bill/save`,
                data: { bill, billDetails }
            };
            http.post(apiOptions).then(res => {
                saveBillCallBack(res);
            });
        }
    };

    const saveBillCallBack = (res) => {
        AlertService.showSuccessMsg();
        if (billId > 0) {
            getBillWithDetailsById();
        }
        else {
            back();
        }
    };

    const getBillWithDetailsById = () => {
        let apiOptions: any = {
            url: `bill/withdetail/${billId}`
        };
        http.get(apiOptions).then(res => {
            getBillWithDetailsByIdCallBack(res);
        });
    };

    const getBillWithDetailsByIdCallBack = (res) => {
        setBill(res.data.bill);
        if (res.data.bill.billStatusId === 1) {
            let billDetails = [];
            for (let detail of res.data.billDetails) {
                detail.uniqueId = getUniqueId();
                billDetails.push(detail);
            }
            setBillDetails(billDetails);
        } else {
            setBillDetails(res.data.billDetails);
        }
        setIsNotApproved(res.data.bill.billStatusId === 1);
    };

    const handleInputChange = (item) => {
        if (item.name === "itemId") {
            const selItem = items.find(x => x.id === item.value);
            setSelItemId(item.value);
            setSelectedItem(prevState => {
                return { ...prevState, ...selItem }
            });
        }
        else {
            handleBillObjChange({ [item.name]: item.value });
            if (item.name === "partyId" || item.name === "transportModeId" || item.name === "billTypeId") {
                item.value = item.value === -1 ? "" : item.value + '';
            }
            else if (item.name === "billNo") {
                item.value = item.value === null ? "" : item.value + '';
            }
            item.validations = validationMap[item.name];
            const validationResult = FormValidator.validate(item);
            const validationData = { [item.name]: validationResult };
            updateErrors(validationData);
        }
    };

    const handleBillObjChange = (data) => {
        setBill(prevState => {
            return { ...prevState, ...data }
        });
    };

    const back = () => {
        history.goBack();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                Parties: { default: true },
                TransportMode: { default: true },
                BillType: { default: true }
            },
            filters: {
                companyId: session.getItem("companyId")
            }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setParties(response.data.Parties);
            setTransportMode(response.data.TransportMode);
            setBillType(response.data.BillType);
        });
    };

    const getItems = () => {
        let apiOptions: any = {
            url: `item/query`,
            data: {}
        };
        http.post(apiOptions).then(res => {
            getItemsCallBack(res);
        });
    };

    const getItemsCallBack = (res) => {
        let result = [{ id: -1, itemName: "Please Select" }];
        if (res.data.length > 0) {
            for (let i in res.data) {
                result.push(res.data[i]);
            }
        }
        setItems(result);
    };

    const addItem = () => {
        if (selItemId === -1) {
            AlertService.showErrorMsg("Please select item.")
        }
        else {
            const uniqueId = getUniqueId();
            const item = selectedItem;
            let billDetail = {
                itemId: item.id,
                itemCode: item.itemCode,
                itemName: item.itemName,
                measuringUnitId: item.measuringUnitId,
                measuringUnit: item.measuringUnit,
                taxPercentage: item.taxPercentage,
                price: item.price,
                bagPerKG: item.bagPerKG,
                noOfBags: 0,
                totalKG: 0,
                cgst: (item.taxPercentage / 2),
                sgst: (item.taxPercentage / 2),
                cgstAmount: 0,
                sgstAmount: 0,
                subTotal: 0,
                total: 0,
                uniqueId: uniqueId
            };
            setBillDetails(prevState => {
                return prevState.concat(billDetail)
            });
            //setSelItemId(-1);
        }
    };

    const updateBillTotal = () => {
        let subTotal: any = 0;
        let total: any = 0;
        let cgstAmount: any = 0;
        let sgstAmount: any = 0;
        for (let detail of billDetails) {
            subTotal += parseFloat(detail.subTotal);
            total += parseFloat(detail.total);
            cgstAmount += parseFloat(detail.cgstAmount);
            sgstAmount += parseFloat(detail.sgstAmount);
        }

        subTotal = subTotal.toFixed(2);
        total = total.toFixed(2);
        cgstAmount = cgstAmount.toFixed(2);
        sgstAmount = sgstAmount.toFixed(2);

        const rec = { subTotal, total, cgstAmount, sgstAmount };
        setBill(prevState => {
            return { ...prevState, ...rec }
        });
    };

    const removeItem = (row) => {
        const items = billDetails.filter((x) => x.uniqueId !== row.uniqueId);
        setBillDetails(items);
    };

    const removeBillItem = (row) => {
        let apiOptions: any = {
            url: `bill/removedetail/${row.id}`
        };
        http.delete(apiOptions).then(res => {
            removeBillItemCallBack(res);
        });
    };

    const removeBillItemCallBack = (res) => {
        const items = billDetails.filter((x) => x.id !== res.data);
        setBillDetails(items);
    };

    const handleBillDetailInputChange = (item) => {
        const uniqueId = item.opt;
        const changedObj = { [item.name]: item.value };
        const details = billDetails.map((detail, i) => {
            if (detail.uniqueId == uniqueId) {
                detail = { ...detail, ...changedObj };
                if (item.value) {
                    const totalKG = detail.noOfBags * detail.bagPerKG;
                    const subTotal = totalKG * detail.price;
                    const cgstAmt = (subTotal * parseFloat(detail.cgst) / 100);
                    const sgstAmt = (subTotal * parseFloat(detail.sgst) / 100);
                    const total = subTotal + cgstAmt + sgstAmt;

                    detail.totalKG = totalKG;
                    detail.subTotal = subTotal.toFixed(2);
                    detail.cgstAmount = cgstAmt.toFixed(2);
                    detail.sgstAmount = sgstAmt.toFixed(2);
                    detail.total = total.toFixed(2);
                }
            }
            return detail;
        });
        setBillDetails(details);
    };

    const approveBill = () => {
        let apiOptions: any = {
            url: `bill/approve/${billId}`,
            data: {}
        };
        http.put(apiOptions).then(res => {
            approveBillCallBack(res);
        });
    };

    const approveBillCallBack = (res) => {
        AlertService.showSuccessMsg();
        getBillWithDetailsById();
    };

    const getNextBillNo = () => {
        let apiOptions: any = {
            url: `bill/next/billno`,
            data: {}
        };
        http.post(apiOptions).then(res => {
            getNextBillNoCallBack(res);
        });
    };

    const getNextBillNoCallBack = (res) => {
        setNextBillNo(res.data);
    };

    const getUniqueId = () => {
        return nanoid();
    };

    useEffect(() => {
        updateBillTotal();
    }, [billDetails]);

    useEffect(() => {
        initLookup();
        getItems();
        if (billId > 0) {
            getBillWithDetailsById();
        }
        else {
            getNextBillNo();
        }
    }, []);

    return (
        <div className="content-container billform-ui">
            <div className="title-font px-4 row justify-content-between py-1">
                <span className="pt-1">Bill</span>
            </div>
            <div className="col-12 mx-auto row pt-3">
                {
                    bill.id === 0 &&
                    <div className="col-sm-4 mt-3">
                        <FxSelect name="partyId" variant="outlined" label="Party Name"
                            options={parties}
                            selectedValue={bill.partyId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            onValueChange={handleInputChange} />
                        {
                            hasError("partyId", "required") &&
                            <div className="validation-msg">Field is required</div>
                        }
                    </div>
                }
                {
                    bill.id > 0 &&
                    <div className="col-sm-4 mt-3">
                        <FxInput name="partyName" variant="outlined" label="Party Name" size="small" fullWidth
                            value={bill.partyName}
                            disabled={true}
                        />
                    </div>
                }

                <div className="col-sm-4 mt-3">
                    <FxSelect name="billTypeId" variant="outlined" label="Bill Type"
                        options={billType}
                        selectedValue={bill.billTypeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("billTypeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="billNo" variant="outlined" label="Bill No" size="small" fullWidth
                        value={bill.billNo}
                        type="number"
                        onValueChange={handleInputChange}
                    />
                    {
                        billId === 0 &&
                        <div className="nxtbill">Next Bill No: {nextBillNo}</div>
                    }
                    {
                        hasError("billNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxDate name="billDate" variant="outlined" label="Bill Date" size="small" fullWidth
                        value={bill.billDate}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("billNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxSelect name="transportModeId" variant="outlined" label="Transport Mode"
                        options={transportMode}
                        selectedValue={bill.transportModeId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                    {
                        hasError("transportModeId", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="veichleNo" variant="outlined" label="Veichle No" size="small" fullWidth
                        value={bill.veichleNo}
                        onValueChange={handleInputChange}
                    />
                    {
                        hasError("veichleNo", "required") &&
                        <div className="validation-msg">Field is required</div>
                    }
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="cgstAmount" variant="outlined" label="CGST Amount" size="small" fullWidth
                        value={bill.cgstAmount}
                        disabled={true}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="sgstAmount" variant="outlined" label="SGST Amount" size="small" fullWidth
                        value={bill.sgstAmount}
                        disabled={true}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="subTotal" variant="outlined" label="Sub Total" size="small" fullWidth
                        value={bill.subTotal}
                        disabled={true}
                    />
                </div>

                <div className="col-sm-4 mt-3">
                    <FxInput name="total" variant="outlined" label="Total" size="small" fullWidth
                        value={bill.total}
                        disabled={true}
                    />
                </div>
            </div>
            {
                isNotApproved &&
                <div className="col-12 row mx-auto pt-3">
                    <div className="col-sm-4 mt-3">
                        <FxSelect name="itemId" variant="outlined" label="Item Name"
                            options={items}
                            selectedValue={selItemId}
                            valueField="id"
                            displayField="itemName"
                            size="small"
                            onValueChange={handleInputChange} />
                    </div>

                    <div className="col-sm-4 mt-3 pt-1">
                        <Button variant="contained" size="small" onClick={addItem}>Add Item</Button>
                    </div>
                </div>
            }

            <div className="px-2 py-1 col-sm-8 pl-3 mobileview">
                {
                    billDetails.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row">
                                        <span className="col-12 cardrow-ui">{item.itemName}</span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">No Of Bags</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="noOfBags" variant="outlined" label="" size="small" fullWidth
                                                    value={item.noOfBags} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {item.noOfBags}
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Bag Kg</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="bagPerKG" variant="outlined" label="" size="small"
                                                    value={item.bagPerKG} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {item.bagPerKG}
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Rate Per Kg</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="price" variant="outlined" label="" size="small"
                                                    value={item.price} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={item.price} />
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Total Kg</span>
                                        <span className="col-4">{item.totalKG}</span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Sub Total</span>
                                        <span className="col-4">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={parseFloat(item.subTotal)} />
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Total</span>
                                        <span className="col-4">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={parseFloat(item.total)} />
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="px-2 py-1 col-sm-12 pl-3 tabview">
                {
                    billDetails.map((item, i) => (
                        <div key={i} className="card-ui py-2">
                            <Card>
                                <CardContent>
                                    <div className="row">
                                        <span className="col-12 cardrow-ui">{item.itemName}</span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">No Of Bags</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="noOfBags" variant="outlined" label="" size="small" fullWidth
                                                    value={item.noOfBags} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {item.noOfBags}
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Bag Kg</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="bagPerKG" variant="outlined" label="" size="small"
                                                    value={item.bagPerKG} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {item.bagPerKG}
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Rate Per Kg</span>
                                        <span className="col-4">
                                            {
                                                isNotApproved &&
                                                <FxInput name="price" variant="outlined" label="" size="small"
                                                    value={item.price} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={item.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={item.price} />
                                                </div>
                                            }
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Total Kg</span>
                                        <span className="col-4">{item.totalKG}</span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Sub Total</span>
                                        <span className="col-4">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={parseFloat(item.subTotal)} />
                                        </span>
                                    </div>
                                    <div className="row pt-2">
                                        <span className="col-4">Total</span>
                                        <span className="col-4">
                                            <i className="fas fa-rupee-sign"></i>
                                            <FxCurrency value={parseFloat(item.total)} />
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))
                }
            </div>

            <div className="col-12 mx-auto row pt-3 px-3 deskview">
                {/* <Container maxWidth="xl" className="mt-2"> */}
                <TableContainer component={Paper}>
                    <Table stickyHeader className="table table-striped">
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head table-font">Item Code</TableCell>
                                <TableCell className="table-head table-font">Item Name</TableCell>
                                <TableCell className="table-head table-font">Measuring Unit</TableCell>
                                <TableCell className="table-head table-font">No Of Bags</TableCell>
                                <TableCell className="table-head table-font">Bag KG</TableCell>
                                <TableCell className="table-head table-font">Rate Per KG</TableCell>
                                <TableCell className="table-head table-font">Total KG</TableCell>
                                <TableCell className="table-head table-font">Sub Total</TableCell>
                                <TableCell className="table-head table-font">Total</TableCell>
                                {
                                    isNotApproved &&
                                    <TableCell className="table-head table-font">Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                billDetails.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <div className="table-font">
                                                {row.itemCode}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                {row.itemName}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                {row.measuringUnit}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                isNotApproved &&
                                                <FxInput name="noOfBags" variant="outlined" label="" size="small"
                                                    value={row.noOfBags} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={row.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {row.noOfBags}
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                isNotApproved &&
                                                <FxInput name="bagPerKG" variant="outlined" label="" size="small"
                                                    value={row.bagPerKG} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={row.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    {row.bagPerKG}
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                isNotApproved &&
                                                <FxInput name="price" variant="outlined" label="" size="small"
                                                    value={row.price} type="number"
                                                    onValueChange={handleBillDetailInputChange}
                                                    opt={row.uniqueId}
                                                />
                                            }
                                            {
                                                !isNotApproved &&
                                                <div className="table-font">
                                                    <i className="fas fa-rupee-sign"></i>
                                                    <FxCurrency value={row.price} />
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                {row.totalKG}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={parseFloat(row.subTotal)} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="table-font">
                                                <i className="fas fa-rupee-sign"></i>
                                                <FxCurrency value={parseFloat(row.total)} />
                                            </div>
                                        </TableCell>
                                        {
                                            isNotApproved && !row.id &&
                                            <TableCell>
                                                <div className="btn-style">
                                                    <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => removeItem(row)}> Remove
                                                            </Button>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        }
                                        {
                                            isNotApproved && row.id > 0 &&
                                            <TableCell>
                                                <div className="btn-style">
                                                    <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => removeBillItem(row)}> Remove
                                                            </Button>
                                                    </span>
                                                </div>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </Container> */}
            </div>
            <div className="row py-4 pr-3 mr-1 justify-content-end">
                <span className="pl-2">
                    <Button variant="contained" size="small" onClick={back}>Back</Button>
                </span>
                {
                    isNotApproved &&
                    <span className="pl-2">
                        <Button variant="contained" size="small" color="primary" onClick={saveBill}>Save</Button>
                    </span>
                }
                {
                    isNotApproved && billId > 0 &&
                    <span className="pl-3">
                        <Button variant="contained" size="small" color="primary" onClick={approveBill}>Approve</Button>
                    </span>
                }
            </div>
        </div>
    )
};

export default BillForm;