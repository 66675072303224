import { combineReducers, createStore } from 'redux';
import { cart } from './cart.reducer';

const rootReducer = combineReducers({
  cart
});

function configureStore(rootReducer?: any) {
  const store = createStore(rootReducer);
  return store;
}

export const store = configureStore(rootReducer);